import ajax from '@/libs/request'

export default {
  // 账号登录
  doLogin: param => {
    return ajax.post('/v1/web/user/doLogin', param)
  },
  // 密码登录
  getdoLogin: param => {
    return ajax.post('/v1/live/operation/control/auth/doLogin', param)
  },
  // 发送验证码
  sendCode: param => {
    return ajax.post('/v1/live/operation/control/sms/verifyCode/sendCode', param)
  },
  // 注册手机号的发送验证码功能
  sendSixCode: param => {
    return ajax.post('/v1/live/operation/control/sms/verifyCode/sendSixCode', param)
  },

  // 通过编号获取店铺名字
  getShopName: param => {
    return ajax.post('/v1/live/operation/control/shopInfo/getShopName', param)
  },

  // 绑定手机号
  sevaUserPhone: param => {
    if (localStorage.getItem('isDDorDK') == 4) {
      return ajax.post('/v1/live/operation/control/userInfo/sevaUserPhoneForLocalLife', param)
    } else {
      return ajax.post('/v1/live/operation/control/userInfo/sevaUserPhoneV2', param)
    }
  },

  // 验证码登录功能
  doLoginByPhone: param => {
    return ajax.post('/v1/live/operation/control/auth/doLoginByPhone', param)
  },
  // 获取公司版本
  getUserVersionDetail: param => {
    return ajax.post('/v1/live/operation/control/company/getUserVersionDetail', param)
  },
  // 获取插件安装的信息
  getPlugDownLoadInfo: () => {
    return ajax.post('/v1/live/operation/control/config/getPlugDownLoadInfoV2', { companyType: localStorage.getItem('isDDorDK') })
  },
  // 获取快手插件安装的信息
  getKwaiDownLoadUrl: param => {
    return ajax.get('/v1/live/operation/control/config/getKwaiDownLoadUrl', param)
  },
  // 退出登录
  loginOut: param => {
    return ajax.post('/v1/live/operation/control/auth/loginOut', param)
  },
  // 获取直播中控宝介绍配置
  getLiveControlIntroduction: param => {
    return ajax.get('/v1/live/operation/control/config/getLiveControlIntroduction', param)
  },
  // 插件使用步骤
  getPlugUseTheSteps: param => {
    return ajax.get('/v1/live/operation/control/config/getPlugUseTheSteps', param)
  },
  // 更改密码或者忘记密码
  updatePassword: param => {
    return ajax.post('/v1/live/operation/control/user/updatePassword', param)
  },
  //埋点接口
  addOperationLog: param => {
    param.companyCode = localStorage.getItem('companyCode') || 0
    param.shopId = localStorage.getItem('shopId') || 0
    param.buyinAccountId = localStorage.getItem('pcbuyinAccountId') || 0
    return ajax.post('/v1/live/operation/control/userOperationLog/create', param)
  },
  //埋点接口
  addOperationLogV2: param => {
    param.companyCode = localStorage.getItem('companyCode') || 0
    param.shopId = localStorage.getItem('shopId') || 0
    param.buyinAccountId = localStorage.getItem('pcbuyinAccountId') || 0
    return ajax.post('/v1/live/operation/control/userOperationLog/createV2', param)
  },
  //ticket换用户信息
  getTokenByTicket: param => {
    return ajax.post('/v1/live/operation/control/auth/getTokenbyTicket', param)
  },
  //快手ticket
  getTokenbyTicketForKwai: param => {
    return ajax.post('/v1/live/operation/control/auth/getTokenbyTicketForKwai', param)
  },
  //快手登录
  kuaishouLogin: param => {
    return ajax.post('/v1/live/operation/control/auth/getKwaiAuthLoginLink', param)
  },

  getDoudianAuthLink: param => {
    return ajax.post('/v1/live/operation/control/auth/getDoudianAuthLink', param)
  },

  //分页查询操作日志
  getUserOperationLoglist: param => {
    return ajax.post('/v1/live/operation/control/userOperationLog/list', param)
  },

  // 同行在用
  getCounterpartsUsedPlugInfo: param => {
    return ajax.get('/v1/live/operation/control/config/getCounterpartsUsedPlugInfo', param)
  },

  // 首页banner
  getHomeBanner: () => {
    return ajax.post('/v1/live/operation/control/config/getHomeBannerV2', { companyType: localStorage.getItem('isDDorDK') })
  },

  // 首页banner
  getHomeBannerForKuai: () => {
    return ajax.post('/v1/live/operation/control/config/getHomeBannerForKwai', { companyType: localStorage.getItem('isDDorDK') })
  },

  // 首页banner
  getHomeBannerForLocalLife: () => {
    return ajax.post('/v1/live/operation/control/config/getHomeBannerForLocalLife', { companyType: localStorage.getItem('isDDorDK') })
  },

  // 获取预设价配置
  getShopPresetPriceSetting: param => {
    return ajax.post('/v1/live/operation/control/shopSystemSetting/getShopPresetPriceSetting', param)
  },

  // 获取预设库存配置
  getShopPresetStockSetting: param => {
    return ajax.post('/v1/live/operation/control/shopSystemSetting/getShopPresetStockSetting', param)
  },

  // 更新店铺配置
  updateShopSystemSetting: param => {
    return ajax.post('/v1/live/operation/control/shopSystemSetting/update', param)
  },

  //查询用户是否登录过插件
  checkUserPlugLoginStatus: param => {
    return ajax.post('/v1/live/operation/control/userOperationLog/checkUserPlugLoginStatus', param)
  },
  //获取浏览器安装教程
  getPlugInstallDoc: param => {
    return ajax.get('/v1/live/operation/control/config/getPlugInstallDoc', param)
  },
  //获取快手浏览器安装教程
  getKwaiPlugUseDoc: param => {
    return ajax.get('/v1/live/operation/control/config/getKwaiPlugUseDoc', param)
  },
  //获取封面教程
  getHomePageCourse: () => {
    return ajax.post('/v1/live/operation/control/config/getHomePageCourseV2', { companyType: localStorage.getItem('isDDorDK') })
  },
  //获取快手封面教程
  getHomePageCourseForKwai: param => {
    return ajax.get('/v1/live/operation/control/config/getHomePageCourseForKwai', param)
  },
  // 抖开服务市场通过code换取openid以及ticket
  getDouyinOpenid: param => {
    return ajax.post('/v1/live/operation/control/auth/getDouyinOpenid', param)
  },

  // 发送验证码-必须登录后才能发送复制接口复制文档复制地址
  sendCodeToCheck: param => {
    return ajax.post('/v1/live/operation/control/sms/verifyCode/needLogin/sendCode', param)
  },

  // 短信账号短信验证码身份验证
  accountAuthentication: param => {
    return ajax.post('/v1/live/operation/control/user/accountAuthentication', param)
  },

  // 修改绑定手机号码
  changePhone: param => {
    return ajax.post('/v1/live/operation/control/user/changePhone', param)
  },

  //解除绑定手机号码
  unBindPhone: param => {
    return ajax.post('/v1/live/operation/control/user/unBindPhoneV2', param)
  },

  //查询店铺绑定账号列表
  getBuyingAccountList: param => {
    return ajax.post('/v1/live/operation/control/user/getBuyingAccountList', param)
  },

  //解绑直播间
  unBindBuyingAccount: param => {
    return ajax.post('/v1/live/operation/control/user/unBindBuyingAccount', param)
  },

  //换绑手机号
  updatePhone: param => {
    if (localStorage.getItem('isDDorDK') == 4) {
      return ajax.post('/v1/live/operation/control/user/updatePhoneForLocalLife', param)
    } else {
      return ajax.post('/v1/live/operation/control/user/updatePhone', param)
    }
  },

  // 获取用户的续费链接
  getUrlForUserType: () => {
    return ajax.post('/v1/live/operation/control/getUrl/getUrlForUserTypeV2', { companyType: localStorage.getItem('isDDorDK') })
  },

  // 判定用户最新的到期时间
  getExpirationTime: param => {
    return ajax.post('/v1/live/operation/control/getShopExpirationTime/getExpirationTime', param)
  },

  // 判断用户是否下载过插件
  checkUserDownLoadStatus: param => {
    return ajax.post('/v1/live/operation/control/userOperationLog/checkUserDownLoadStatus', param)
  },

  // 获取弹窗次数
  getPopCount: param => {
    return ajax.post('/v1/live/operation/control/shopSystemSetting/getPopCount', param)
  },

  // 修改弹窗次数
  updatePopCount: param => {
    return ajax.post('/v1/live/operation/control/shopSystemSetting/updatePopCount', param)
  },

  //获取二维码
  getBdshQRCode: param => {
    return ajax.post('/v1/live/operation/control/localLife/getQRCode', param)
  },

  //扫码二维码后获取用户信息
  getUserInfoByToken: param => {
    return ajax.post('/v1/live/operation/control/localLife/getUserInfoByToken', param)
  },

  //拿本地生活教程
  getHomePageCourseForlocalLife: () => {
    return ajax.get('/v1/live/operation/control/config/getHomePageCourseForlocalLife')
  },

  //创建订单
  createOrder: param => {
    return ajax.post('/v1/live/operation/control/localLife/createOrder', param)
  },

  //获取列表
  getSkuInfo: () => {
    return ajax.post('/v1/live/operation/control/localLife/getSkuInfo')
  },

  //获取列表
  getDarenSkuInfo: (params) => {
    return ajax.post('/v1/live/operation/control/plugSale/skuInfo', params)
  },

  //获取状态
  getOrderStatus: (param) => {
    return ajax.post('/v1/live/operation/control/localLife/getOrderStatus', param)
  },

  // 下载客户端
  getClientNowVersionInfo: () => {
    return ajax.post('/v1/live/operation/control/client/getClientNowVersionInfo')
  },

  //本地团购登录
  localLifeLoginByPhone: (params) => {
    return ajax.post('/v1/live/operation/control/auth/localLifeLoginByPhone', params)
  },

  create: (params) => {
    return ajax.post('/v1/live/operation/control/plugSaleOrder/create', params)
  },

  getDarenOrderStatus: (params) => {
    return ajax.post('/v1/live/operation/control/plugSaleOrder/getOrderStatus', params)
  },

  updateCompanyBindRoomLiveStatus: (params) => {
    return ajax.post('/v1/live/operation/control/company/liveRoom/updateCompanyBindRoomLiveStatus', params)
  },

  getLiveRoomPayInfo: (params) => {
    return ajax.post('/v1/live/operation/control/plug/live/room/pay/getLiveRoomPayInfo', params)
  },

  createLiveRoomPayOrder: (params) => {
    return ajax.post('/v1/live/operation/control/plug/live/room/pay/create', params)
  },

  getOrderStatusForRoom: (params) => {
    return ajax.post('/v1/live/operation/control/plug/live/room/pay/getOrderStatus', params)
  },

  getComapnyLiveRoomInfoByCompanyCode: params => {
    return ajax.post('/v1/live/operation/control/company/liveRoom/getComapnyLiveRoomInfoByCompanyCode', params)
  },

  createAdLog: params => {
    return ajax.post('/v1/live/operation/control/adLog/createAdLog', params)
  },

  getOrderHistory: params => {
    return ajax.post('/v1/live/operation/control/plugSaleOrder/getOrderHistory', params)
  },

  xiaohongshuLoginByPhone: params => {
    return ajax.post('/v1/live/operation/control/auth/xiaohongshuLoginByPhone', params)
  },

  getXiaohongshuAuthLink: params => {
    return ajax.post('/v1/live/operation/control/auth/getXiaohongshuAuthLink', params)
  },

  getTokenbyTicketForXiaohongshu: params => {
    return ajax.post('/v1/live/operation/control/auth/getTokenbyTicketForXiaohongshu', params)
  },

  getXiaohongshuShopBindAuthLink: params => {
    return ajax.post('/v1/live/operation/control/auth/getXiaohongshuShopBindAuthLink', params)
  },

  getXiaohongshuShopUnbindAuthLink: params => {
    return ajax.post('/v1/live/operation/control/auth/getXiaohongshuShopUnbindAuthLink', params)
  },

  getShopInfo: params => {
    return ajax.post('/v1/live/operation/control/shopInfo/getShopInfo', params)
  },
}