import axios from 'axios'
import router from '../router'
import { Message } from 'element-ui'
// import Store from 'electron-store'

// const store = new Store();
const service = axios.create({
    // 设置超时时间
    timeout: 3600000,
    baseURL: '/',
    headers: {
        'Cache-Control': 'no-cache'
    }
})

// 即设置post的请求头为application/x-www-form-urlencoded;charset=UTF-8
service.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'

let pending = [] //声明一个数组用于存储每个ajax请求的取消函数和ajax标识
let whiteList = [
    '/v1/live/operation/control/userOperationLog/list',
    '/v1/live/operation/control/userOperationLog/checkUserPlugLoginStatus',
    '/v1/live/operation/control/userOperationLog/create',
    '/v1/live/operation/control/umsGetBuyinList/getBuyinList',
    '/v1/live/operation/control/umsGetComment/getGoodCommnetDetail',
    '/v1/live/operation/control/umsGetComment/getBadCommnetDetail',
    '/v1/live/operation/control/userOperationLog/checkUserDownLoadStatus',
    '/v1/live/operation/control/getSkuInfo/getSkuList',
    '/v1/live/operation/control/getSkuInfo/getLitteYellowCarSkuList',
    '/v1/live/operation/control/shopSystemSetting/getPopCount',
    'v1/live/operation/control/user/getBuyingAccountList',
    '/v1/live/operation/control/umsGetBuyinList/getKwaiList',
    '/v1/live/operation/control/kuaishou/getAllGroup',
    '/v1/live/operation/control/kuaishou/editShopGroupInfo',
    '/v1/live/operation/control/localLife/getUserInfoByToken',
    '/v1/live/operation/control/umsSmallAccountAuthorizationInfo/getBuyinAuthStatus',
    '/v1/live/operation/control/plug/live/room/pay/getLiveRoomPayInfo',
    '/v1/live/operation/control/adLog/createAdLog'
] //不取消的接口list
let cancelToken = axios.CancelToken
let removePending = ever => {
    for (let p in pending) {
        if (pending[p].u === ever.url + '&' + ever.method) {
            //当当前请求在数组中存在时执行函数体
            pending[p].f() //执行取消操作
            pending.splice(p, 1) //把这条记录从数组中移除
        }
    }
}

/**
 * 请求前拦截
 * 用于处理需要在请求前的操作
 */
service.interceptors.request.use(
    config => {
        config.headers.brandId = localStorage.getItem('brandInfo')
            ? JSON.parse(localStorage.getItem('brandInfo')).brandId
            : ''
        config.headers.userId = localStorage.getItem('userInfo')
            ? JSON.parse(localStorage.getItem('userInfo')).userId
            : ''
        let token = localStorage.getItem('token')
        // console.log('1', token)
        // console.log('2', JSON.stringify(localStorage))
        if (token) {
            config.headers.token = token
        }
        // console.log('3', JSON.stringify(config.headers))
        if (config.method === 'get') {
            config.params = {
                ...config.params
            }
            //IE浏览器加上时间戳
            if (!!window.ActiveXObject || 'ActiveXObject' in window) {
                config.params._t = Date.parse(new Date()) / 1000
            }
        }
        //白名单内的接口可重复请求
        // console.log(config.url)
        if (whiteList.indexOf(config.url) === -1) {
            removePending(config) //在一个ajax发送前执行一下取消操作
            config.cancelToken = new cancelToken(c => {
                // 这里的ajax标识我是用请求地址&请求方式拼接的字符串，当然你可以选择其他的一些方式
                pending.push({ u: config.url + '&' + config.method, f: c })
                // console.log([...pending])
            })
        }
        // console.log(4, JSON.stringify(config))
        return config
    },
    error => {
        return Promise.reject(error)
    }
)
/**
 * 请求响应拦截
 * 用于处理需要在请求返回后的操作
 */
service.interceptors.response.use(
    response => {
        removePending(response.config) //在一个ajax响应后再执行一下取消操作，把已经完成的请求从pending中移除
        // console.log(response)
        const responseCode = response.status
        // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
        // 否则的话抛出错误
        let code = response.data ? response.data.code : null
        if (response.config.responseType === 'blob') {
            // console.log('进来了这里21321321',response.data)
        }

        switch (responseCode) {
            case 201:
                if (router.currentRoute.path === '/login') return
                // 清除token
                router.push({
                    path: '/login',
                    query: {
                        redirect: router.currentRoute.fullPath
                    }
                })
                break
            // 401：未登录
            case 401:
                if (router.currentRoute.path === '/login') return
                // 清除token
                router.push({
                    path: '/login',
                    query: {
                        redirect: router.currentRoute.fullPath
                    }
                })
                break


            // 403: token过期
            // eslint-disable-next-line no-fallthrough
            case 403:
                if (router.currentRoute.path === '/login') return
                // 清除token
                router.push({
                    path: '/login',
                    query: {
                        redirect: router.currentRoute.fullPath
                    }
                })
                break
        }
        if (code === 0) {
            return Promise.resolve(response.data || {})
        } else if (code === 401) {
            // 清除token，转态返回401标识token已过期
            if (router.currentRoute.path === '/login') return
            sessionStorage.removeItem('token')
            localStorage.removeItem('companyCode')
            localStorage.removeItem('companyName')
            localStorage.removeItem('token')
            localStorage.removeItem('userInfo')
            window.location.href = `${location.origin}/login`
        } else {
            if (!response.config.url.includes('getOrderStatus')) {
                Message({
                    message: response.data.msg,
                    type: 'error',
                    duration: 3000
                })
            }
            return Promise.reject(response.data || {})
        }
    },
    error => {
        if (!error.message) {
            return
        }
        // 服务器返回不是 2 开头的情况，会进入这个回调
        // 可以根据后端返回的状态码进行不同的操作
        const responseCode = error.response ? error.response.status : ''
        switch (responseCode) {
            // 401：未登录
            case 401:
                // 跳转登录页
                if (router.currentRoute.path === '/login') return
                router.replace({
                    path: '/login',
                    query: {
                        redirect: router.currentRoute.fullPath
                    }
                })
                break
            // 403: token过期
            case 403:
                if (router.currentRoute.path === '/login') return
                // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
                localStorage.removeItem('token')
                window.location.href = `${location.origin}/login`
                // setTimeout(() => {
                //   router.replace({
                //     path: '/login',
                //     query: {
                //       redirect: router.currentRoute.fullPath
                //     }
                //   })
                // }, 1000)
                break
            // 404请求不存在
            case 404:
                // errorMsg('网络请求不存在')
                Message({
                    message: '网络请求不存在',
                    type: 'error',
                    duration: 3000
                })
                break
            // 其他错误，直接抛出错误提示
            default:
                Message({
                    message: error.data || error,
                    type: 'error',
                    duration: 3000
                })
            // error.response && errorMsg(error.response.data.message)
        }
        return Promise.reject(error.data || error)
    }
)
// const errorMsg = () => {
//   let dom = document.getElementsByClassName('el-message--error')
//   if (dom.length <= 0) console.log('error')
// }
//图片上传
export const uploadFile = formData => {
    const res = service.request({
        method: 'post',
        url: '/utils/upload/img',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
    })
    return res
}
export default service