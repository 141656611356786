<template>
  <div class="all_layout">
    <el-container>
      <!--      :width='widthsize'-->
      <!--      v-if='isDDorDK==0'-->
      <el-aside>
        <SiderBar />
      </el-aside>
      <el-container>
        <!--        :height='heightsize'-->
        <el-header v-if="showExit">
          <HeadBar />
        </el-header>
        <div v-else>
          <HeadBar/>
        </div>
        <el-main :style="showExit ? 'height:calc(100vh - 60px)' : 'height:calc(100vh)'">
          <AppMain />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import { HeadBar, SiderBar, AppMain } from '@/components'

export default {
  name: 'MainLayout',
  components: {
    HeadBar,
    SiderBar,
    AppMain
  },
  data() {
    return {
      widthsize: '',
      heightsize: '',
      isDDorDK: '',
    }
  },
  computed: {
    showExit() {
      const isClient = localStorage.getItem('isClient') + ''
      return isClient !== '1'
    }
  }
}
</script>

<style>
.all_layout {
  height: 100vh;
  overflow-y: hidden;
}

.el-header {
  border-bottom: 1px solid #f3f4f5;
  box-shadow: 2px 0px 6px 0px rgba(218, 217, 224, 0.6);
  padding: 0px;
  height: 60px;
}

.el-aside {
  width: 210px !important;
  text-align: center;
  background-color: #ffffff;
  height: 100vh;
  color: #303133;
  box-shadow: 2px 0px 6px 0px rgba(218, 217, 224, 0.6);
}

.el-main {
  color: #333;
  background: #f0f2f5;
  height: calc(100vh - 60px);
  padding: 12px !important;
}

body > .el-container {
  margin-bottom: 40px;
}
</style>
