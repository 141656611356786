<template>
  <div>
    <div v-if='numberDialog'>
      <el-dialog :visible.sync='phoneNumberDialogVisible' width='516px' height='312px' center @close='closedialog'>
        <template slot='title'>
          <div class='title_div'>绑定手机号，快捷登录</div>
        </template>
        <el-form ref='form' :model='form' :rules='ruleValidate' class="el_forms">
          <el-form-item prop='phone' label='手机号'>
            <el-input v-model='form.phone' placeholder='请输入手机号' />
          </el-form-item>
          <el-form-item prop='checkCode' label='验证码'>
            <el-input placeholder='请输入验证码' v-model='form.checkCode' maxlength='6'>
              <template slot='append'>
                <span v-if='timer'>{{ second + 's' }}</span>
                <span v-if='!timer' @click='sendCodeToCheck' style='cursor: pointer;'>获取验证码</span>
              </template>
            </el-input>
          </el-form-item>
        </el-form>
        <div class='footer'>
          <div class='login_button' @click='getchangpassword(loginStatus)'>{{ '保存信息并立即下载中控插件' }}
          </div>
          <div class="download_button" @click="toDownload()">
            <div>不绑定,直接下载</div>
            <el-popover placement="top-start" width="255" trigger="hover" content="不绑定手机号的情况下，可直接使用抖店授权登录中控宝">
              <div slot="reference" class="question_logo"></div>
            </el-popover>
          </div>
        </div>
      </el-dialog>
    </div>
    <installVideo :drawer='drawer' @handleDrawerClose='handleDrawerClose'></installVideo>
  </div>
</template>

<script>
import user from '@/api/user'
import installVideo from '@/views/components/installVideo'
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'bindphone',
  props: {
    numberDialog: {
      type: Boolean,
      default: false
    }
  },
  components: {
    installVideo
  },
  data() {
    return {
      phoneNumberDialogVisible: false,
      drawer: false,
      ruleValidate: {
        phone: [
          { required: true, message: '手机号不能为空', trigger: 'blur' },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: '手机号码格式不正确',
            trigger: 'blur'
          }
        ],
        checkCode: [
          { required: true, message: '验证码不能为空', trigger: 'blur' },
        ],
      },
      form: {
        phone: ''
      },
      timer: null,
      second: 60,
      loginStatus: false,
    }
  },
  computed: {},
  watch: {
    numberDialog: {
      handler: function (val) {
        if (val) {
          this.checkUserDownLoadStatus()
        }
      },
      immediate: true
    }
  },
  methods: {
    checkUserDownLoadStatus() {
      user.checkUserDownLoadStatus().then(res => {
        if (res.code === 0) {
          this.loginStatus = res.data
          // 打开弹窗
          this.phoneNumberDialogVisible = true
        }
      }).catch(err => {
        console.log(err)
      })
    },
    sendCodeToCheck() {
      if (this.timer) return
      user.sendSixCode({ phone: this.form.phone }).then(res => {
        if (res.code === 0) {
          this.$message.success('发送验证码成功')
          this.timer = setInterval(() => {
            if (this.second === 0) {
              clearInterval(this.timer)
              this.timer = null
              this.second = 60
            } else {
              this.second--
            }
          }, 1000)
          console.log('发送一遍验证码')
        }
      }).catch(err => {
        console.log(err)
      })
    },
    handlephoneNumberDialogVisibleClose() {
      // 调用父组件方法关闭窗口
      this.$emit('handlebindNumberDialogClose')
    },

    getchangpassword(val) {
      this.$refs.form.validate(valid => {
        if (valid) {
          // 没下载过插件就下载
          if (!val) {
            // 操作日志（新用户下载）   35
            user.addOperationLog({
              operationType: 35,
              requestParam: '',
              sourceType: 0,
            })
            if (localStorage.getItem('isDDorDK') == 3) {
              // 增加下载逻辑
              user.getKwaiDownLoadUrl().then(res => {
                if (res.code === 0) {
                  window.open(res.data[0].downloadUrl)
                  this.drawer = true
                }
              }).catch(err => {
                console.log(err)
              })
            } else {
              // 增加下载逻辑
              user.getPlugDownLoadInfo().then(res => {
                if (res.code === 0) {
                  window.open(res.data[0].downloadUrl)
                  this.drawer = true
                }
              }).catch(err => {
                console.log(err)
              })
            }
          }
          let userInfo = JSON.parse(localStorage.getItem('userInfo'))
          const params = {
            checkCode: this.form.checkCode,
            phone: this.form.phone,
            shopId: localStorage.getItem('shopId') || ''
          }
          user.sevaUserPhone(params).then(res => {
            if (res.code === 0) {
              this.$message.success('绑定成功')
              userInfo.userPhone = this.form.phone
              localStorage.setItem('userInfo', JSON.stringify(userInfo))
              this.$emit('handlebindNumberDialogClose')
            }
          }).catch(err => {
            console.log(err)
          })
        }
      })
    },
    closedialog() {
      this.$emit('handlebindNumberDialogClose')
    },
    // 关闭安装教程
    handleDrawerClose() {
      this.drawer = false
    },
    toDownload() {
      this.phoneNumberDialogVisible = false
      // pc端登陆官网下载链接 操作日志
      user.addOperationLog({
        operationType: 22,
        requestParam: '',
        sourceType: 0
      })
      if (localStorage.getItem('isDDorDK') == 3) {
              // 增加下载逻辑
              user.getKwaiDownLoadUrl().then(res => {
                if (res.code === 0) {
                  window.open(res.data[0].downloadUrl)
                  this.drawer = true
                }
              }).catch(err => {
                console.log(err)
              })
            } else {
              // 增加下载逻辑
              user.getPlugDownLoadInfo().then(res => {
                if (res.code === 0) {
                  window.open(res.data[0].downloadUrl)
                  this.drawer = true
                }
              }).catch(err => {
                console.log(err)
              })
            }
    }
  }
}
</script>

<style lang='less' scoped>
/deep/ .el-dialog--center .el-dialog__body {
  padding: 0px 25px 20px !important;
}

/deep/ .el-dialog__header {
  padding: 25px 25px 8px !important;
}


.title_div {
  height: 32px;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #303133;
  line-height: 32px;
  text-align: center;
}

.title_div_two {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #C0BECA;
  line-height: 22px;
}

.marginTop {
  margin-bottom: 20px;
}

.body_phone {
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #303133;
  line-height: 22px;
  margin-top: 32px;
  margin-bottom: 24px;
}

.footer {
  cursor: pointer;
  margin-top: 20PX;

  .login_button {
    height: 58px;
    line-height: 58PX;
    border-radius: 5px;
    background: #6647FF;
    border: 1PX solid #6647FF;
    text-align: center;
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    margin-top: 28px;
    margin-bottom: 16px;
  }
}

.img_bg {
  img {
    width: 436px;
    height: 200px;
  }
}

::v-deep .el_forms .el-input__inner {
  height: 42px !important;
}

.download_button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #6647FF;
  line-height: 18px;
}

.question_logo {
  width: 16px;
  height: 16px;
  background-image: url('../assets/question.png');
  background-size: contain;
  margin-left: 4px;
}
</style>