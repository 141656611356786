<template>
    <div>
        <div v-if='numberDialog'>
            <el-dialog :visible.sync='phoneNumberDialogVisible' width='516px' height='312px' center
                :close-on-click-modal='false' :before-close='handlephoneNumberDialogVisibleClose'>
                <template slot='title'>
                    <div class='title_div'>账号管理</div>
                </template>
                <div class="main" v-if="status === 0">
                    <div style="margin-bottom:8px">当前绑定手机号：-</div>
                    <div class="changeMobiles">
                        <div class='title_div_two'>建议绑定手机号，登录更方便</div>
                        <el-form ref='form' :model='form' :rules='ruleValidate'>
                            <el-form-item prop='phone'>
                                <el-input placeholder='请输入手机号' v-model='form.phone'></el-input>
                            </el-form-item>
                            <el-form-item prop='checkCode'>
                                <el-input placeholder='请输入验证码' v-model='form.checkCode'>
                                    <template slot='append'>
                                        <span v-if='timer'>{{ second + 's' }}</span>
                                        <span v-if='!timer' @click='sendCodeToCheck' style='cursor: pointer;'>发送验证码</span>
                                    </template>
                                </el-input>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
                <div class='footer' v-if="status === 0">
                    <div class='cancel_button' @click='handlephoneNumberDialogVisibleClose'>
                        取消</div>
                    <div class='login_button' @click='savePassword'>保存</div>
                </div>
                <div class="success-box" v-if="status === 1">
                    <div class="success-img">
                    </div>
                    <div class="success-description">
                        手机号绑定成功，后续可以使用手机号登录啦！
                    </div>
                </div>
            </el-dialog>
        </div>
    </div>
</template>
  
<script>
import user from '@/api/user'

export default {
    name: 'bindMobile',
    props: {
        numberDialog: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            ruleValidate: {
                phone: [
                    { required: true, message: '手机号不能为空', trigger: 'blur' },
                    {
                        pattern: /^1[3456789]\d{9}$/,
                        message: '手机号码格式不正确',
                        trigger: 'blur'
                    }
                ],
                checkCode: [
                    { required: true, message: '验证码不能为空', trigger: 'blur' },
                ],
            },
            //是否显示
            phoneNumberDialogVisible: true,
            status: 0, //处于的状态
            accountAuthenticationKey: '',
            form: {
                phone: '',
                checkCode: '',
            },
            timer: null,
            second: 60,
        }
    },
    computed: {},
    watch: {
    },
    methods: {
        reset() {
            this.timer = null
            this.form = {
                phone: '',
                checkCode: ''
            }
            this.accountAuthenticationKey = ''
            this.second = 60
            this.status = 0
        },
        handlephoneNumberDialogVisibleClose() {
            // 调用父组件方法关闭窗口
            this.$emit('handleMobileDialogClose')
            //重置参数
            if (this.status === 1) {
                location.reload();
            }
            this.reset()
        },
        sendCodeToCheck() {
            if (this.timer) return
            user.sendSixCode({ phone: this.form.phone }).then(res => {
                if (res.code === 0) {
                    this.$message.success('发送验证码成功')
                    this.timer = setInterval(() => {
                        if (this.second === 0) {
                            clearInterval(this.timer)
                            this.timer = null
                            this.second = 60
                        } else {
                            this.second--
                        }
                    }, 1000)
                    console.log('发送一遍验证码')
                }
            }).catch(err => {
                console.log(err)
            })
        },
        savePassword() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                    const params = {
                        checkCode: this.form.checkCode,
                        phone: this.form.phone,
                        companyCode: localStorage.getItem('companyCode') || '',
                        ordId: ''
                    }
                    user.sevaUserPhone(params).then(res => {
                        if (res.code === 0) {
                            userInfo.userPhone = this.form.phone
                            localStorage.setItem('userInfo', JSON.stringify(userInfo))
                            this.status = 1
                        }
                    }).catch(err => {
                        console.log(err)
                    })
                }
            })
        }
    }
}
</script>
  
<style lang='less' scoped>
/deep/ .el-dialog--center .el-dialog__body {
    padding: 20px 24px 24px !important;
}

/deep/ .el-dialog__header {
    border-bottom: 1px solid #f7f7f7;
    padding-bottom: 17px
}

.title_div {
    text-align: left;
    font-family: PingFangSC-Medium, PingFang SC;
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    line-height: 25px;
}

.title_div_two {
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 12px;
    font-size: 14px;
    color: #6647fd;
}

.marginTop {
    margin-bottom: 20px;
}

.main {
    font-size: 16px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #303133;
}

.body_phone {
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #303133;
    line-height: 22px;
    margin-top: 32px;
    margin-bottom: 24px;
}

.footer {
    width: 465PX;
    display: flex;
    align-items: flex-end;
    justify-content: right;
    cursor: pointer;
    margin-top: 40PX;

    .cancel_button {
        width: 64PX;
        height: 32PX;
        border-radius: 2PX;
        border: 1PX solid #D8D8D8;
        font-size: 14PX;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 32PX;
        text-align: center;
    }

    .login_button {
        width: 64PX;
        height: 32PX;
        background: #6647FF;
        border: 1PX solid #6647FF;
        border-radius: 2PX;
        font-size: 14PX;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 32PX;
        text-align: center;
        margin-left: 16PX;
    }
}

.success-box {
    height: 92px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 44px;
    margin-bottom: 42px;

    .success-img {
        width: 48px;
        height: 48px;
        background-image: url('../assets/gou.png');
        background-size: 48px 48px;
    }

    .success-description {
        font-size: 18px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #303133;
    }
}
</style>