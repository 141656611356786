<template>
  <div>
    <!--    底部下拉抽屉-->
    <!--    :visible.sync='drawer'-->
    <el-drawer title='安装教程' v-if='drawer' :visible.sync='drawerOpen' :direction='direction'
      :before-close='handleDrawerClose' size='76%' center>
      <div class='drawer_title'>
        <div class='drawer_title_center'>
          <div class='drawer_title_center_item' v-for='(item, index) in browserdata' :key='index'
            @click='changdrawerTitleType(index)'>
            <div :class="[drawerTitleType === index ? 'drawer_title_center_item_active' : '']" style='display: flex'>
              <div class='drawer_title_center_item_img'>
                <img :src='item.imageUrl'>
              </div>
              <div style='margin-left: 5px;'>
                <span> {{ item.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class='drawer_body' v-if='browserdata.length > 0 && browserdata[drawerTitleType]'>
        <div class='drawer_body_video'>
          <video controls :src='videoUrl' muted>
          </video>
        </div>
        <div class='drawer_body_text'>
          <div class='drawer_body_text_title'>
            <div><img :src='browserdata[drawerTitleType].imageUrl'></div>
            <div>{{ browserdata[drawerTitleType].title }}</div>
          </div>
          <div
            style="position: relative;text-align: start;font-size:14px;color: #909399;padding-left:27px;border-bottom:1px solid #E2E1EA;padding-bottom:16px;">
            <img src="../../assets/warning.png"
              style="width:18px;height:18px;margin-right:4px;position: absolute;left:-1px;top:1px" />
            若出现插件无法安装的情况（如：未能成功加载拓展程序，<span style="color:#6647FF;cursor:pointer;" @click="getBrowser()">请下载官方正版浏览器</span>）
          </div>
          <div style="margin-left:7px;">
            <div v-for='(item, index) in browserdata[drawerTitleType].contentList' :key='index' class='contentListItem'>
              <div class='contentListItem_span_one'> 0{{ index + 1 }}</div>
              <div class='contentListItem_span_two'>&nbsp;&nbsp;&nbsp;&nbsp;{{ item }}</div>
            </div>
            <div class='contentListItem' v-if="drawerTitleType === 0">
              <div class="contentListItem_span_two">注：苹果电脑用户请点击<a href="https://browser.360.cn/ee/mac/index.html"
                  target="'_blank'"
                  style="text-decoration: none;color:#336DF4">https://browser.360.cn/ee/mac/index.html</a><br>下载360极速浏览器pro使用（请勿在App
                store下载）</div>
            </div>
          </div>

          <div class='add_code'>
            <div class='img_code'>
              <img src='https://sumlive-1256030678.cos.ap-shanghai.myqcloud.com/image/20240819-104010.jpg' alt="plugDownloadIcon">
            </div>
            <div class='text_class'>还是不会安装？微信扫码联系我</div>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import user from '@/api/user'

export default {
  name: 'installVideo',
  watch: {
    drawer(val) {
      if (val) {
        // 获取浏览器的相关安装教程
        this.getPlugInstallDoc()
      }
    }
  },
  props: {
    drawer: {
      type: Boolean,
      default: false
    },
  },
  created() {
    // this.getPlugInstallDoc()
  },
  data() {
    return {
      drawerOpen: true,
      direction: 'btt',
      browserdata: [],//浏览器相关数据
      drawerTitleType: 0, //默认点亮第一个
      videoUrl: ''  //视频的链接
    }
  },
  methods: {
    getBrowser() {
      if (this.drawerTitleType == 0) {
        window.open('https://browser.360.cn/ee/', '_black')
      } else if (this.drawerTitleType == 1) {
        window.open('https://browser.360.cn/se/', '_black')
      } else if (this.drawerTitleType == 2) {
        window.open('https://www.google.cn/chrome/index.html', '_black')
      } else if (this.drawerTitleType == 3) {
        window.open('https://www.microsoft.com/zh-cn/edge/download?form=MA13FJ', '_black')
      }
    },
    // 获取浏览器安装教程
    getPlugInstallDoc() {
      // 获取浏览器的安装教程
      if (localStorage.getItem('isDDorDK') == 3) {
        user.getKwaiPlugUseDoc().then(res => {
          if (res.code === 0) {
            this.browserdata = res.data
            // 获取默认播放的链接
            this.videoUrl = this.browserdata[this.drawerTitleType].videoUrl
          }
        }).catch(err => {
          console.log(err)
        })
      } else {
        user.getPlugInstallDoc().then(res => {
          if (res.code === 0) {
            this.browserdata = res.data
            // 获取默认播放的链接
            this.videoUrl = this.browserdata[this.drawerTitleType].videoUrl
          }
        }).catch(err => {
          console.log(err)
        })
      }
    },
    // 关闭安装教程
    handleDrawerClose() {
      // 调用父组件方法关闭窗口
      this.$emit('handleDrawerClose')
      this.videoUrl = ''
    },
    //切换不同浏览器的教程
    changdrawerTitleType(val) {
      this.drawerTitleType = val
      this.videoUrl = this.browserdata[val].videoUrl
    }
  }
}
</script>

<style lang='less' scoped>
.drawer_title {
  width: calc(100% - 80px);
  margin: auto;
  text-align: center;
  border-bottom: 1px solid #E2E1EA;
  margin-bottom: 30px;

  .drawer_title_center {
    width: 840px;
    margin: auto;
    text-align: center;
    display: flex;
  }

  .drawer_title_center_item {
    cursor: pointer;
    display: flex;
    align-items: center;
    vertical-align: middle;
    width: 25%;
    text-align: left;
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #303133;
    line-height: 22px;
    padding-bottom: 3px;

    .drawer_title_center_item_img {
      width: 24px;
      height: 24px;

      img {
        width: 24px;
        height: 24px;
      }
    }
  }

  .drawer_title_center_item_active {
    color: #6647FF;
    border-bottom: #6647ff solid 4px;
  }
}

.drawer_body {
  padding-top: 15px;
  position: relative;
  width: calc(100% - 80px);
  height: 500px;
  margin: auto;
  text-align: center;
  display: flex;
  vertical-align: middle;
  padding-bottom: 92px;

  .drawer_body_video {
    width: 50%;
    height: 500px;
    margin-right: 110px;

    video {
      width: 100%;
      height: 500px;
    }
  }

  .drawer_body_text {
    //margin-left: 110px;
    text-align: center;
    flex: 1;

    .drawer_body_text_title {
      display: flex;
      align-items: center;
      vertical-align: middle;
      //width: 730px;
      font-weight: 600;
    }
  }

  .contentListItem {
    text-align: left;
    margin-top: 24px;
    display: flex;
    vertical-align: middle;
    align-items: center;

    .contentListItem_span_one {
      font-size: 28px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 28px;
      background: linear-gradient(180deg, #4117FF 0%, #B4A4FD 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .contentListItem_span_two {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #303133;
      line-height: 28px;
    }
  }
}

.add_code {
  width: 220px;
  height: 140px;
  position: absolute;
  right: 60px;
  bottom: 0;
  text-align: center;
  box-sizing: border-box;
  padding-top: 16px;

  .img_code {
    width: 88px;
    height: 88px;
    border-radius: 8px;
    border: 1px solid #E5E6EB;
    background: #FFFFFF;
    padding: 10px;
    margin: auto;

    //box-sizing: border-box;
    img {
      width: 88px;
      height: 88px;
    }
  }

  .text_class {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #606266;
    margin-top: 12px;
  }
}

::v-deep .el-drawer__body {
  padding-bottom: 60px;
}
</style>