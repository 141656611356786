<template>
  <div>
    <el-dialog
      class="expired-dialog"
      title="订购到期提醒"
      :visible.sync="expiredDialogVisible"
      width="500PX"
    >
      <div class="title">您的订购已到期，请前往续费！</div>
      <div class="desc">
        续费后可继续使用直播中控宝进行商品自动讲解、自动发评、自动发福袋等操作。
      </div>
      <div class="button_list">
        <div class="cancel_button" @click="finishbuy">我已续费</div>
        <div class="button" @click="tobuy">立即续费</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import user from '@/api/user'

export default {
  name: 'outTime',
  data() {
    return {
      expiredDialogVisible: false
    }
  },
  created() {
    this.getTime()
  },
  methods: {
    getTime(val) {
      this.expiredDialogVisible = false
      let expiresTimeStamp = localStorage.getItem('expiresTimeStamp')
      if (expiresTimeStamp) {
        expiresTimeStamp = parseInt(expiresTimeStamp)
      }
      if (expiresTimeStamp < parseInt(Date.now() / 1000)) {
        if (val && val == 'finish') {
          this.$message.warning('未获取到订单信息')
        }
        this.expiredDialogVisible = true
      }
    },
    getDate() {
      let now = new Date()
      return now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate()
    },
    tobuy() {
      if (localStorage.getItem('isDDorDK') == 4) {
        this.$store.commit('setBuyLocalDialogVisible', 2)
      } else if (localStorage.getItem('isDDorDK') == 1 || localStorage.getItem('isDDorDK') == 2) {
        this.$store.commit('setDarenDialogVisible', true)
      } else if (localStorage.getItem('isDDorDK') == 3) {
        this.$store.commit('setKuaiShouBuy', true)
      } else if (localStorage.getItem('isDDorDK') == 5) {
        this.$store.commit('setWxDialog', true)
      } else if (localStorage.getItem('isDDorDK') == 6) {
        this.$store.commit('setTbDialog', true)
      } else if (localStorage.getItem('isDDorDK') == 7) {
        this.$store.commit('setXhsBuy', true)
      }else {
        // 记录操作日志
        user.addOperationLog({
          operationType: 1,
          requestParam: '',
          sourceType: 0
        })
        // 请求相关链接
        user
          .getUrlForUserType()
          .then(res => {
            if (res.code === 0 && res.data) {
              window.open(res.data)
            }
          })
          .catch(err => {
            console.log(err)
          })
      }
    },
    // 完成购买
    finishbuy() {
      user
        .getExpirationTime({
          companyCode: localStorage.getItem('companyCode') || ''
        })
        .then(res => {
          localStorage.setItem('expiresTimeStamp', res.data.sassEndTime)
          this.getTime('finish')
          console.log(res)
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>

<style lang="less" scoped>
.expired-dialog {
  /deep/ .el-dialog__header {
    padding: 16px 24px 16px 24px;
    border-bottom: 1px solid #f7f7f7;

    .el-dialog__title {
      height: 25px;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 25px;
    }
  }

  /deep/ .el-dialog__body {
    padding: 16px 24px 24px 24px;
  }

  .title {
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #303133;
    line-height: 22px;
  }

  .desc {
    //height: 20PX;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #aba9b6;
    line-height: 20px;
    margin-top: 16px;
  }
}

.button_list {
  width: 100%;
  display: flex;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  justify-content: flex-end;
  margin-top: 16px;

  .button {
    width: 92px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    background: #6647ff;
    border-radius: 2px;
    cursor: pointer;
    margin-left: 16px;
  }
}

.cancel_button {
  width: 92px;
  height: 32px;
  border-radius: 2px;
  border: 1px solid #d8d8d8;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 32px;
  text-align: center;
  cursor: pointer;
}
</style>
