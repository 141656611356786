<template>
  <div>
    <div v-if="numberDialog">
      <el-dialog
        :visible.sync="phoneNumberDialogVisible"
        width="516px"
        height="312px"
        center
        :close-on-click-modal="false"
        :before-close="handlephoneNumberDialogVisibleClose"
      >
        <template slot="title">
          <div class="title_div">账号管理</div>
        </template>
        <div class="main" v-if="status < 4">
          <div style="margin-bottom: 8px">
            当前绑定手机号：{{ form.phone.replace(/(\d{3})\d*(\d{4})/, '$1****$2') }}
          </div>
          <div class="checkCode" v-if="status > 0 && status < 3">
            <div class="title_div_two">为确保您的账户安全，请先进行身份验证</div>
            <el-form ref="form" :model="form" :rules="ruleValidate">
              <el-form-item prop="checkCode">
                <el-input placeholder="请输入验证码" v-model="form.checkCode" maxlength="6">
                  <template slot="append">
                    <span v-if="timer">{{ second + 's' }}</span>
                    <span v-if="!timer" @click="sendCodeToCheck" style="cursor: pointer">
                      发送验证码
                    </span>
                  </template>
                </el-input>
              </el-form-item>
            </el-form>
          </div>
          <div class="changeMobiles" v-if="status === 3">
            <div class="title_div_two">请输入新的手机号</div>
            <el-form ref="newform" :model="newform" :rules="ruleValidate">
              <el-form-item prop="phone">
                <el-input placeholder="请输入手机号" v-model="newform.phone"></el-input>
              </el-form-item>
              <el-form-item prop="checkCode">
                <el-input placeholder="请输入验证码" v-model="newform.checkCode">
                  <template slot="append">
                    <span v-if="newtimer">{{ newSecond + 's' }}</span>
                    <span v-if="!newtimer" @click="sendCodeToNewCheck" style="cursor: pointer">
                      发送验证码
                    </span>
                  </template>
                </el-input>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="footer" v-if="status < 4">
          <div v-if="status === 0 && isDDorDK != 4" class="cancel_button" @click="status = 1">
            解绑
          </div>
          <div v-if="status === 0" class="login_button" @click="status = 2">更换</div>
          <div
            v-if="status > 0 && status < 4"
            class="cancel_button"
            @click="handlephoneNumberDialogVisibleClose"
          >
            取消
          </div>
          <div v-if="status > 0 && status < 3" class="login_button" @click="getchangpassword">
            确定
          </div>
          <div v-if="status === 3" class="login_button" @click="savePassword">保存</div>
        </div>
        <div v-if="status > 3" class="success-box">
          <div class="success-img"></div>
          <div v-if="status === 4" class="success-description">解绑成功</div>
          <div v-if="status === 5" class="success-description">更换手机号绑定成功</div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import user from '@/api/user'

export default {
  name: 'changeMobile',
  props: {
    numberDialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      ruleValidate: {
        phone: [
          { required: true, message: '手机号不能为空', trigger: 'blur' },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: '手机号码格式不正确',
            trigger: 'blur'
          }
        ],
        checkCode: [{ required: true, message: '验证码不能为空', trigger: 'blur' }]
      },
      //是否显示
      phoneNumberDialogVisible: true,
      form: {
        phone: JSON.parse(localStorage.getItem('userInfo')).userPhone, //当前绑定的账号
        checkCode: '' //验证码
      },
      status: 0, //处于的状态
      timer: null,
      second: 60,
      accountAuthenticationKey: '',
      newform: {
        phone: '',
        checkCode: ''
      },
      newtimer: null,
      newSecond: 60,
      isDDorDK: localStorage.getItem('isDDorDK')
    }
  },
  computed: {},
  watch: {},
  methods: {
    reset() {
      this.status = 0
      this.form.checkCode = ''
      this.newtimer = null
      this.newform = {
        phone: '',
        checkCode: ''
      }
      this.timer = null
      this.second = 60
      this.accountAuthenticationKey = ''
      this.newSecond = 60
    },
    handlephoneNumberDialogVisibleClose() {
      // 调用父组件方法关闭窗口
      this.$emit('handleNumberDialogClose')
      //相应判断
      if (this.status === 4) {
        if (localStorage.getItem('loginType') != 1) {
          this.$message.success('解绑成功，请重新登录')
          setTimeout(() => {
            this.exit()
          }, 1500)
        } else {
          location.reload()
        }
      }
      if (this.status === 5) {
        if (localStorage.getItem('loginType') != 1) {
          this.$message.success('手机号已变更，请重新登录')
          setTimeout(() => {
            this.exit()
          }, 1500)
        } else {
          location.reload()
        }
      }
      //重置参数
      this.reset()
    },
    sendCodeToCheck() {
      if (this.timer) return
      user
        .sendCodeToCheck({ phone: this.form.phone })
        .then(res => {
          if (res.code === 0) {
            this.timer = setInterval(() => {
              if (this.second === 0) {
                clearInterval(this.timer)
                this.timer = null
                this.second = 60
              } else {
                this.second--
              }
            }, 1000)
            console.log('发送一遍验证码')
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    getchangpassword() {
      this.$refs.form.validate(valid => {
        if (valid) {
          user
            .accountAuthentication({ checkCode: this.form.checkCode })
            .then(async res => {
              if (res.code === 0) {
                this.accountAuthenticationKey = res.data.accountAuthenticationKey
                if (this.status === 1) {
                  //解绑请求
                  const res = await user.unBindPhone({
                    accountAuthenticationKey: this.accountAuthenticationKey,
                    checkCode: this.form.checkCode,
                    companyCode: localStorage.getItem('companyCode'),
                    id: JSON.parse(localStorage.getItem('userInfo')).userId,
                    phone: this.form.phone,
                    companyType: localStorage.getItem('isDDorDK')
                  })
                  if (res.code === 0) {
                    this.status = 4
                  } else {
                    this.$message.error(res.msg)
                  }
                } else {
                  //更改请求
                  this.status = 3
                }
              }
            })
            .catch(err => {
              console.log(err)
            })
        }
      })
    },
    sendCodeToNewCheck() {
      if (this.newtimer) return
      let validateFieldList = []
      this.$refs.newform.validateField(['phone'], async valid => {
        if (!valid) {
          //校验通过走这里,每通过一次,会往这个数组里加一个""
          validateFieldList.push(valid)
          if (validateFieldList.length == 1 && validateFieldList.every(item => item === '')) {
            //这里写校验通过的业务逻辑
            this.newtimer = setInterval(() => {
              if (this.newSecond === 0) {
                clearInterval(this.newtimer)
                this.newtimer = null
                this.newSecond = 60
              } else {
                this.newSecond--
              }
            }, 1000)
            user
              .sendCodeToCheck({ phone: this.newform.phone })
              .then(res => {
                if (res.code === 0) {
                  console.log('发送一遍验证码')
                } else {
                  this.$message.error(res.msg)
                }
              })
              .catch(err => {
                console.log(err)
              })
          }
          //校验不通过走这里
          return
        }
      })
    },
    savePassword() {
      this.$refs.newform.validate(valid => {
        if (valid) {
          user
            .updatePhone({
              newPhone: this.newform.phone,
              checkCode: this.newform.checkCode,
              accountAuthenticationKey: this.accountAuthenticationKey,
              companyCode: localStorage.getItem('companyCode'),
              id: JSON.parse(localStorage.getItem('userInfo')).userId
            })
            .then(res => {
              if (res.code === 0) {
                this.status = 5
                this.handlephoneNumberDialogVisibleClose()
                console.log('修改手机号码成功')
              }
            })
            .catch(err => {
              console.log(err)
            })
        }
      })
    },
    exit() {
      user
        .loginOut()
        .then(res => {
          if (res.code === 0) {
            sessionStorage.removeItem('token')
            localStorage.removeItem('companyCode')
            localStorage.removeItem('companyName')
            localStorage.removeItem('token')
            localStorage.removeItem('userInfo')
            this.$store.commit('setCompanyList', [])
            this.$router.push('/login')
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-dialog--center .el-dialog__body {
  padding: 20px 24px 24px !important;
}

/deep/ .el-dialog__header {
  border-bottom: 1px solid #f7f7f7;
  padding-bottom: 17px;
}

.title_div {
  text-align: left;
  font-family: PingFangSC-Medium, PingFang SC;
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  line-height: 25px;
}

.title_div_two {
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 12px;
  font-size: 14px;
  color: #aba9b6;
}

.marginTop {
  margin-bottom: 20px;
}

.main {
  font-size: 16px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #303133;
}

.body_phone {
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #303133;
  line-height: 22px;
  margin-top: 32px;
  margin-bottom: 24px;
}

.footer {
  width: 465px;
  display: flex;
  align-items: flex-end;
  justify-content: right;
  cursor: pointer;
  margin-top: 40px;

  .cancel_button {
    width: 64px;
    height: 32px;
    border-radius: 2px;
    border: 1px solid #d8d8d8;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 32px;
    text-align: center;
  }

  .login_button {
    width: 64px;
    height: 32px;
    background: #6647ff;
    border: 1px solid #6647ff;
    border-radius: 2px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 32px;
    text-align: center;
    margin-left: 16px;
  }
}

.success-box {
  height: 92px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 44px;
  margin-bottom: 42px;

  .success-img {
    width: 48px;
    height: 48px;
    background-image: url('../assets/gou.png');
    background-size: 48px 48px;
  }

  .success-description {
    font-size: 18px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: #303133;
  }
}
</style>
