<template>
  <div class="total">
    <div class="total_top" v-if="showExit">
      <div>
        <img src="@/assets/logo.png" />
      </div>
      <div>
        <span>直播中控宝</span>
      </div>
    </div>
    <el-menu
      mode="vertical"
      :unique-opened="true"
      collapse-transition
      :default-active="activeMenu"
      text-color="#7D7B89"
      active-text-color="#6647FF"
    >
      <sidebar-item :routes="routes" />
    </el-menu>
  </div>
</template>

<script>
import SidebarItem from './sidebarItem.vue'

export default {
  name: 'SiderBar',
  components: {
    SidebarItem
  },

  computed: {
    activeMenu() {
      // console.log('监测现在的路由',this.$route.path,this.$route.path.split('/'))
      let path = ''
      let routeList = this.$route.path.split('/')
      if (routeList && routeList.length > 3) {
        path = `/${routeList[1]}/${routeList[2]}`
      } else {
        path = this.$route.path
      }

      return path
    },
    routes() {
      // console.log("routes",this.$router.options.routes)
      return this.$router.options.routes
    },
    showExit() {
      const isClient = localStorage.getItem('isClient') + ''
      return isClient !== '1'
    }
  },
  data() {
    return {
      url: '',
      classType: '',
      widthsize: '',
      heightsize: ''
    }
  },
}
</script>

<style lang="less" scoped>
.total {
  height: 100%;
  overflow: hidden;
  padding-left: 12px;
  padding-right: 12px;

  .total_top {
    display: flex;
    height: 80px;
    vertical-align: middle;
    justify-content: center;
    align-items: center;

    img {
      width: 40px;
      height: 40px;
      margin-right: 12px;
    }

    span {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: #303133;
      line-height: 22px;
    }
  }
}

.el-menu {
  border-right: none;
  text-align: left;
}
</style>
