<template>
  <div>
    <div class="totle" v-if="showExit">
      <div class="left_totle">
        <div class="left_totle_one" @click="roWeb">前往官网</div>
        <div class="left_totle_one" @click="getBook('useBook')">我要开票</div>
        <div v-if="versionType" class="left_totle_img">
          <!--专业版-->
          <img
            class="imgs"
            v-if="versionType === 2 && isDDorDK == 0"
            src="@/assets/doudianEdition.png"
          />
          <img
            class="imgs"
            v-if="versionType === 2 && (isDDorDK == 1 || isDDorDK == 2)"
            src="@/assets/darenEdition.png"
          />
          <img
            class="imgs"
            v-if="versionType === 2 && isDDorDK == 3"
            src="@/assets/kuaishouEdition.png"
          />
          <img
            class="imgs"
            v-if="versionType === 2 && isDDorDK == 4"
            src="@/assets/bdshZhuanye.png"
          />
          <img class="imgs" v-if="versionType === 2 && isDDorDK == 5" src="@/assets/perfe.png" />
          <img class="imgs" v-if="versionType === 2 && isDDorDK == 6" src="@/assets/tbPer.png" />
          <img class="imgs" v-if="versionType === 2 && isDDorDK == 7" src="@/assets/xhsPer.png" />
          <!--试用版-->
          <img
            class="imgs"
            v-if="
              versionType === 1 && isDDorDK != 4 && isDDorDK != 5 && isDDorDK != 6 && isDDorDK != 7
            "
            src="@/assets/edition2.png"
          />
          <!--试用版-->
          <img class="imgs" v-if="versionType === 1 && isDDorDK == 4" src="@/assets/tryImg.png" />
          <img class="imgs" v-if="versionType === 1 && isDDorDK == 5" src="@/assets/tryy.png" />
          <img class="imgs" v-if="versionType === 1 && isDDorDK == 6" src="@/assets/tbTry.png" />
          <img class="imgs" v-if="versionType === 1 && isDDorDK == 7" src="@/assets/xhsTry.png" />
          <!--旗舰版-->
          <img v-if="versionType === 3" src="@/assets/edition3.png" />
        </div>
        <div v-if="!expires" class="left_totle_two">有效期至{{ sassEndTime }}</div>
        <div v-if="expires" class="left_totle_three">已到期，请尽快续费</div>
        <div
          v-if="isDDorDK != 4 && isDDorDK != 5 && isDDorDK != 6"
          class="finish_pay"
          @click="toBuyMoreTime"
        >
          续费
        </div>
        <div v-if="isDDorDK == 4">
          <div v-if="versionType === 1" class="finish_pay" @click="bdshBuy">订购</div>
          <div v-if="versionType === 2" class="finish_pay" @click="bdshContinue">续费</div>
        </div>
        <div v-if="isDDorDK == 5">
          <div v-if="versionType === 1" class="finish_pay" @click="wxBuy">订购</div>
          <div v-if="versionType === 2" class="finish_pay" @click="wxContinue">续费</div>
        </div>
        <div v-if="isDDorDK == 6">
          <div v-if="versionType === 1" class="finish_pay" @click="tbBuy">订购</div>
          <div v-if="versionType === 2" class="finish_pay" @click="tbBuy">续费</div>
        </div>
        <div class="left_totle_last_hover">
          <div class="left_totle_last_hover_title" @click="getOpenRightMenu">
            <div>{{ companyName }}</div>
            <div>
              <i v-if="!OpenRightMenu" class="el-icon-arrow-down el-icon--right"></i>
              <i v-if="OpenRightMenu" class="el-icon-arrow-up el-icon--right"></i>
            </div>
          </div>
          <div class="menu" :style="{ display: !OpenRightMenu ? 'none' : 'inline-block' }">
            <div class="menu_item" v-if="companyListinfo.length > 1" @click="choosecompanyName()">
              <div class="menu_img">
                <img src="@/assets/headBarRight/changeshop.png" />
              </div>
              <div class="menu_img_s">
                <img src="@/assets/headBarRight/changeshop_s.png" />
              </div>
              <div class="menu_text">切换店铺</div>
            </div>
            <div v-if="userphone" class="menu_item" @click="changPhoneNumber">
              <div class="menu_img">
                <img src="@/assets/headBarRight/changephone.png" />
              </div>
              <div class="menu_img_s">
                <img src="@/assets/headBarRight/changephone_s.png" />
              </div>
              <div class="menu_text">账号管理</div>
            </div>
            <div v-else class="menu_item" @click="bindMobileNumber">
              <div class="menu_img">
                <img src="@/assets/headBarRight/changephone.png" />
              </div>
              <div class="menu_img_s">
                <img src="@/assets/headBarRight/changephone_s.png" />
              </div>
              <div class="menu_text">账号管理</div>
            </div>
            <div v-if="userphone" class="menu_item" @click="changpassword">
              <div class="menu_img">
                <img src="@/assets/headBarRight/password.png" />
              </div>
              <div class="menu_img_s">
                <img src="@/assets/headBarRight/password_s.png" />
              </div>
              <div class="menu_text">修改密码</div>
            </div>
            <div v-if="showExit" class="menu_item" @click="exit">
              <div class="menu_img">
                <img src="@/assets/headBarRight/exit.png" />
              </div>
              <div class="menu_img_s">
                <img src="@/assets/headBarRight/exit_s.png" />
              </div>
              <div class="menu_text">退出登录</div>
            </div>
          </div>
        </div>
      </div>
      <el-dialog
        title="更改密码"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        width="516px"
        height="441px"
        :before-close="handleClose"
        class="login_doalog"
        center
      >
        <el-form ref="form" :model="form" :rules="ruleValidate" style="margin-top: 10px">
          <el-form-item prop="phone">
            <div
              style="
                height: 22px;
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #303133;
                line-height: 22px;
              "
            >
              手机号：{{ form.phone.replace(/(\d{3})\d*(\d{4})/, '$1****$2') }}
            </div>
          </el-form-item>
          <el-form-item prop="checkCode">
            <el-input placeholder="请输入验证码" v-model="form.checkCode">
              <template slot="append">
                <span v-if="timer">{{ second + 's' }}</span>
                <span v-if="!timer" @click="sendCode" style="cursor: pointer">发送验证码</span>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="newPassword">
            <el-input
              v-model="form.newPassword"
              placeholder="请输入6~30位新密码"
              :type="openPassword ? 'text' : 'password'"
              maxlength="30"
            >
              <img
                v-if="!openPassword"
                slot="suffix"
                src="@/assets/passwordclose.png"
                style="margin-right: 20px; margin-top: 10px"
                @click="openPassword = !openPassword"
              />
              <img
                v-if="openPassword"
                slot="suffix"
                src="@/assets/passwordopen.png"
                style="margin-right: 20px; margin-top: 10px"
                @click="openPassword = !openPassword"
              />
            </el-input>
          </el-form-item>
          <el-form-item prop="confirmNewPassword">
            <el-input
              v-model="form.confirmNewPassword"
              placeholder="确认密码"
              :type="openNewPassword ? 'text' : 'password'"
              maxlength="30"
            >
              <img
                v-if="!openNewPassword"
                slot="suffix"
                src="@/assets/passwordclose.png"
                style="margin-right: 20px; margin-top: 10px"
                @click="openNewPassword = !openNewPassword"
              />
              <img
                v-if="openNewPassword"
                slot="suffix"
                src="@/assets/passwordopen.png"
                style="margin-right: 20px; margin-top: 10px"
                @click="openNewPassword = !openNewPassword"
              />
            </el-input>
          </el-form-item>
        </el-form>
        <button class="login_button" @click="getchangpassword">更改密码</button>
      </el-dialog>
      <el-dialog class="peer-in-use-dialog" title="" :visible.sync="peerInUseVisible" width="672px">
        <div
          style="cursor: pointer"
          v-for="(item, index) in counterpartsUsedPlugInfo"
          :key="index"
          @click="getBook(item.docUrl)"
        >
          <el-image :src="item.imageUrl">
            <div slot="placeholder" class="image-slot">
              加载中
              <span class="dot">...</span>
            </div>
          </el-image>
        </div>
      </el-dialog>

      <!--    联系客服的弹窗-->
      <el-dialog
        class="peer-in-use-dialog"
        title=""
        :visible.sync="QRcodeVisible"
        width="900px"
        height="499px"
        :close-on-click-modal="false"
        :show-close="false"
      >
        <div>
          <div class="close_time_right" @click="closeQRcodeVisible">直接关闭 X</div>
          <div v-if="loginStatus" class="close_time_left">{{ QRcodeSecond }}秒后自动关闭</div>
          <!--        <img src='https://tagvvcloud-material-center-prod.oss-cn-hangzhou.aliyuncs.com/icon/QRcodeVisible.png'>-->
          <el-image
            src="https://tagvvcloud-material-center-prod.oss-cn-hangzhou.aliyuncs.com/live-operation-control/%E8%81%94%E7%B3%BB%E5%AE%A2%E6%9C%8Dbanner.jpg"
            class="close_time_img"
          ></el-image>
        </div>
      </el-dialog>

      <changemobile
        :numberDialog="numberDialog"
        @handleNumberDialogClose="handleNumberDialogClose"
      ></changemobile>
      <bindphone
        :numberDialog="openState"
        @handlebindNumberDialogClose="handlebindNumberDialogClose"
      ></bindphone>
      <bindmobile
        :numberDialog="mobileDialog"
        @handleMobileDialogClose="handleMobileDialogClose"
      ></bindmobile>
    </div>
    <buyLocal></buyLocal>
    <buyDaren></buyDaren>
    <buyKuaiShou></buyKuaiShou>
    <RoomBuying></RoomBuying>
    <buyWx></buyWx>
    <buyTb></buyTb>
    <buyXhs></buyXhs>
  </div>
</template>

<script>
import user from '@/api/user'
import changemobile from '@/components/changemobile'
import bindphone from '@/components/bindphone'
import bindmobile from '@/components/bindmobile'
import buyLocal from '@/components/localBuy'
import buyDaren from '@/components/darenBuy'
import buyKuaiShou from '@/components/kuaishouBuy'
import RoomBuying from '@/components/roomBuying.vue'
import buyWx from '@/components/wxBuy'
import buyTb from '@/components/tbBuy'
import buyXhs from '@/components/xhsBuy'

export default {
  name: 'HeadBar',
  components: {
    changemobile,
    bindphone,
    bindmobile,
    buyLocal,
    buyDaren,
    RoomBuying,
    buyKuaiShou,
    buyWx,
    buyTb,
    buyXhs
  },
  data() {
    return {
      openPassword: false, //是否打开密码
      openNewPassword: false, //是否打开忘记密码的
      companyName: '', //店铺名称
      versionType: '', // 版本类型
      expires: '',
      sassEndTime: '', // 有效期
      companyListinfo: [], // 公司列表信息
      // widthsize: '',  //尺寸
      // widthsize2: '',  //尺寸
      OpenRightMenu: false, //是否打开
      dialogVisible: false, //更改密码的弹窗
      phoneNumberDialogVisible: false, // 更换手机号弹窗是否打开
      form: {
        phone: '',
        newPassword: '',
        checkCode: '',
        confirmNewPassword: ''
      },
      ruleValidate: {
        phone: [
          { required: true, message: '手机号不能为空', trigger: 'blur' },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: '手机号码格式不正确',
            trigger: 'blur'
          }
        ],
        newPassword: [
          { required: true, message: '密码不能为空', trigger: 'blur' },
          {
            pattern: /^.{6,30}$/,
            message: '密码长度为6-30位',
            trigger: 'blur'
          }
        ],
        checkCode: [{ required: true, message: '验证码不能为空', trigger: 'blur' }],
        confirmNewPassword: [
          { required: true, message: '密码不能为空', trigger: 'blur' },
          {
            pattern: /^.{6,30}$/,
            message: '密码长度为6-30位',
            trigger: 'blur'
          }
        ]
      },
      timer: null, // 验证码的倒计时
      second: 60, // 倒计时为60s
      peerInUseVisible: false,
      QRcodeVisible: false, // 联系客服弹窗
      counterpartsUsedPlugInfo: [],
      QRcodeSecond: 0, // 广告关闭的时间
      QRcodetimer: null, // 验证码的倒计时的定时器
      loginStatus: false, // 是否是新用户
      numberDialog: false,
      // bindNumberDialog: false,
      isDDorDK: '',
      isDDorDKmarginRight: true,
      openState: false,
      flag: 1,
      mobileDialog: false
    }
  },
  computed: {
    userphone() {
      let phone = ''
      if (JSON.parse(localStorage.getItem('userInfo')).userPhone) {
        phone = JSON.parse(localStorage.getItem('userInfo')).userPhone
      } else {
        phone = ''
      }
      return phone
    },
    showExit() {
      const isClient = localStorage.getItem('isClient') + ''
      return isClient !== '1'
    }
  },
  watch: {},
  mounted() {
    this.getcompanyName()
    this.getcompanyList()
    // this.getsize()
    this.getCounterpartsUsedPlugInfo()
    this.isDDorDK = localStorage.getItem('isDDorDK')
    // this.getcheckUserPlugLoginStatus()
    this.isPopCount()
  },
  methods: {
    //本地生活订购
    bdshBuy() {
      this.$store.commit('setBuyLocalDialogVisible', 1)
    },
    //本地生活续约
    bdshContinue() {
      this.$store.commit('setBuyLocalDialogVisible', 2)
    },
    //wx订购
    wxBuy() {
      this.$store.commit('setWxDialog', true)
    },
    //wx续约
    wxContinue() {
      this.$store.commit('setWxDialog', true)
    },
    //tb订购
    tbBuy() {
      this.$store.commit('setTbDialog', true)
    },
    //判断是否只弹出了一次
    async isPopCount() {
      if (this.isDDorDK == 0) {
        if (!this.userphone) {
          const res = await user.getPopCount({
            shopId: localStorage.getItem('shopId') || ''
          })
          if (res.code === 0) {
            if (!res.data.popCount) {
              this.bindPhoneNumber()
              await user.updatePopCount({
                shopId: localStorage.getItem('shopId')
              })
            }
          }
        }
      }
    },
    // 用来判断是否登陆过插件   true登陆过
    getcheckUserPlugLoginStatus() {
      user
        .checkUserPlugLoginStatus()
        .then(res => {
          if (res.code === 0) {
            this.loginStatus = !res.data
            this.QRcodeVisible = !res.data
            if (!res.data) {
              user.addOperationLog({
                operationType: 27,
                requestParam: '',
                sourceType: 0
              })
              this.QRcodeSecond = 5
              this.QRcodetimer = setInterval(() => {
                if (this.QRcodeSecond === 0) {
                  clearInterval(this.QRcodetimer)
                  this.QRcodeVisible = false
                } else {
                  this.QRcodeSecond--
                }
              }, 1000)
            }
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    //广告出现的倒计时
    openQRcodeVisible() {
      user.addOperationLog({
        operationType: 27,
        requestParam: '',
        sourceType: 0
      })
      this.QRcodeVisible = true
      this.loginStatus = false
      // this.QRcodeSecond = 5
      // this.QRcodetimer = setInterval(() => {
      //   if (this.QRcodeSecond === 0) {
      //     clearInterval(this.QRcodetimer)
      //     this.QRcodeVisible = false
      //   } else {
      //     this.QRcodeSecond--
      //   }
      // }, 1000)
    },

    // 点击直接关闭需要清空定时器
    closeQRcodeVisible() {
      clearInterval(this.QRcodetimer)
      this.QRcodeVisible = false
    },

    getCounterpartsUsedPlugInfo() {
      user.getCounterpartsUsedPlugInfo().then(res => {
        this.counterpartsUsedPlugInfo = res.data
      })
    },
    getBook(val) {
      if (val === 'useBook') {
        window.open(
          'https://sumwhy0902.feishu.cn/share/base/form/shrcniNyto5XKRC2EagmyFDQyHJ',
          '_blank'
        )
      } else {
        window.open(val, '_blank')
      }
    },
    // getsize() {
    // const el = document.body.offsetWidth
    // if (el < 1920) {
    //   this.widthsize = Math.floor((el / 1920) * 140) + 'px'
    //   this.widthsize2 = Math.floor((el / 1920) * 120) + 'px'
    //   this.isDDorDKmarginRight = false
    // } else {
    //   this.widthsize = '140px'
    //   this.widthsize2 = '120px'
    //   this.isDDorDKmarginRight = true
    // }
    // },
    getOpenRightMenu() {
      this.OpenRightMenu = !this.OpenRightMenu
    },
    getcompanyName() {
      this.companyName = localStorage.getItem('companyName') || ''
      const companyCode = localStorage.getItem('companyCode') || ''
      user
        .getUserVersionDetail({ companyCode: companyCode })
        .then(res => {
          if (res.code === 0) {
            this.versionType = res.data.versionType // 版本类型
            this.sassEndTime = res.data.sassEndTime // 有效期
            this.expires = res.data.expires
            localStorage.setItem('expiresTimeStamp', res.data.expiresTimeStamp)
            localStorage.setItem('version', res.data.versionType)
            localStorage.setItem('liveRoomNum', res.data.liveRoomNum)
          }
        })
        .catch(err => {
          console.log(err)
        })
    },

    // 更换手机号
    changPhoneNumber() {
      this.numberDialog = true
    },

    handleNumberDialogClose() {
      this.numberDialog = false
    },

    //账号管理弹窗
    bindMobileNumber() {
      this.mobileDialog = true
    },

    handleMobileDialogClose() {
      this.mobileDialog = false
    },

    // 绑定手机号弹窗
    bindPhoneNumber() {
      this.openState = true
    },

    // 关闭绑定手机号
    handlebindNumberDialogClose() {
      this.flag++
      this.openState = false
    },

    changpassword() {
      this.form = {
        phone: JSON.parse(localStorage.getItem('userInfo')).userPhone,
        newPassword: '',
        checkCode: ''
      }
      this.$nextTick(() => {
        this.$refs['form'].clearValidate()
      })
      this.openPassword = false
      this.openNewPassword = false
      this.dialogVisible = true
      clearInterval(this.timer)
      this.timer = null
      this.second = 60
    },
    handleClose() {
      this.dialogVisible = false
    },
    sendCode() {
      if (this.timer) return
      if (this.form.phone === '') {
        this.$message.warning('请填写手机号码')
        return
      }
      user
        .sendCode({ phone: this.form.phone })
        .then(res => {
          if (res.code === 0) {
            this.timer = setInterval(() => {
              if (this.second === 0) {
                clearInterval(this.timer)
                this.timer = null
                this.second = 60
              } else {
                this.second--
              }
            }, 1000)
            console.log('发送一遍验证码')
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    getchangpassword() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.confirmNewPassword !== this.form.newPassword) {
            this.$message.warning('两次输入的密码不一致，请重新输入')
            return
          }
          const sendData = JSON.parse(JSON.stringify(this.form))
          sendData.newPassword = this.$md5(sendData.newPassword)
          user
            .updatePassword(sendData)
            .then(res => {
              console.log(res)
              this.$message.success('密码修改成功')
              this.dialogVisible = false
            })
            .catch(err => {
              // this.loading = false
              console.log(err)
            })
        }
      })
    },
    getcompanyList() {
      const userInfo = JSON.parse(localStorage.getItem('userInfo')) || null
      if (userInfo) {
        this.companyListinfo = userInfo.companyList
      }
    },
    toBuyMoreTime() {
      // 记录操作日志
      user.addOperationLog({
        operationType: 1,
        requestParam: '',
        sourceType: 0
      })
      if (this.isDDorDK == 1 || this.isDDorDK == 2) {
        this.$store.commit('setDarenDialogVisible', true)
        return
      }
      if (this.isDDorDK == 3) {
        this.$store.commit('setKuaiShouBuy', true)
        return
      }
      if (this.isDDorDK == 7) {
        this.$store.commit('setXhsBuy', true)
        return
      }
      user
        .getUrlForUserType()
        .then(res => {
          if (res.code === 0 && res.data) {
            window.open(res.data)
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    choosecompanyName() {
      if (this.companyListinfo.length > 1) {
        this.$router.push({
          path: '/choosecompany'
        })
      }
    },
    toDownloadOld() {
      window.open(
        'https://sumlive-1256030678.cos.ap-shanghai.myqcloud.com/plug/pulgV3/%E7%9B%B4%E6%92%AD%E4%B8%AD%E6%8E%A7%E5%AE%9D2.3.0.zip'
      )
    },
    exit() {
      user
        .loginOut()
        .then(res => {
          if (res.code === 0) {
            sessionStorage.removeItem('token')
            localStorage.removeItem('companyCode')
            localStorage.removeItem('companyName')
            localStorage.removeItem('token')
            localStorage.removeItem('userInfo')
            localStorage.removeItem('isDDorDK')
            localStorage.removeItem('pcbuyinAccountId')
            this.$store.commit('setCompanyList', [])
            this.$router.push('/login')
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    roWeb() {
      this.$router.push('/oWeb')
    }
  }
}
</script>

<style lang="less" scoped>
.totle {
  min-width: 750px;
  text-align: right;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.left_totle {
  width: 100%;
  height: 60px;
  line-height: 60px;
  display: flex;
  justify-content: end;
  vertical-align: middle;
  align-items: center;

  // 通用文字
  font-size: 14px;
  color: #7d7b89;
  font-family: PingFangSC-Medium, PingFang SC;

  .left_totle_one {
    min-width: 60px;
    font-weight: 500;
    cursor: pointer;
    margin-right: 40px;
  }

  .left_totle_one:hover {
    color: #6647ff;
  }

  .left_totle_img {
    margin-right: 30px;
    font-size: 0;

    img {
      width: 96px;
      height: 28px;
      vertical-align: middle;
    }

    .imgs {
      width: 78px;
      height: 28px;
      vertical-align: middle;
    }
  }

  .left_totle_two {
    font-weight: 400;
    color: #aba9b9;
    margin-right: 40px;
    cursor: pointer;
  }

  .left_totle_three {
    font-weight: 400;
    color: #ff5c77;
    margin-right: 20px;
  }

  .finish_pay {
    background: #e3defe;
    border-radius: 18px;
    border: 1px solid #6647ff;
    font-weight: 400;
    color: #6647ff;
    width: 68px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    margin-right: 40px;
    cursor: pointer;
  }

  .left_totle_last_hover {
    //height: 20PX;
    font-weight: 500;
    line-height: 20px;
    //margin-top: 20PX;
    margin-right: 20px;
    cursor: pointer;
  }

  .left_totle_last_hover:hover {
    .menu {
      display: inline-block;
    }
  }

  .left_totle_last_hover_title {
    display: flex;
    vertical-align: middle;
    align-items: center;
  }
}

.el-dropdown-link {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #5b5966;
  line-height: 20px;
}

.choosecompanyName_item {
  height: 38px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #303033;
  line-height: 38px;
  text-align: center;
  cursor: pointer;

  :hover {
    background: #eeeeee;
    border-radius: 0px 0px 4px 4px;
  }
}

.choosecompanyName_item:hover {
  background: #eeeeee;
  border-radius: 0px 0px 4px 4px;
}

.login0ut_item {
  height: 38px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #303033;
  line-height: 38px;
  text-align: center;
  cursor: pointer;
}

.login0ut_item:hover {
  background: #eeeeee;
  border-radius: 0px 0px 4px 4px;
}

.qrcode_text {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  text-align: center;
  font-weight: 600;
  color: #303133;
  line-height: 20px;
  margin-bottom: 8px;
}

.qrcode_img {
  padding-left: 4px;
  width: 140px;
  height: 140px;
}

.menu {
  width: 180px;
  background: #ffffff;
  text-align: center;
  z-index: 999;
  position: fixed;
  top: 58px;
  right: 1vw;

  //display: none;
  .menu_item {
    height: 52px;
    display: flex;
    vertical-align: middle;
    align-items: center;
    //justify-content: center;

    .menu_img {
      width: 16px;
      height: 16px;
      margin-right: 10px;
      margin-left: 12px;
      display: inline-block;

      img {
        width: 16px;
        height: 16px;
      }
    }

    .menu_img_s {
      width: 16px;
      height: 16px;
      margin-left: 12px;
      margin-right: 10px;
      display: none;

      img {
        width: 16px;
        height: 16px;
      }
    }

    .menu_text {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #303133;
      line-height: 20px;
    }
  }

  .menu_item:hover {
    //background-color: #6647FF;

    .menu_img {
      display: none;
    }

    .menu_img_s {
      display: inline-block;
    }

    .menu_text {
      color: #6647ff;
    }
  }

  .menu_item_last {
    height: 52px;
    display: flex;
    vertical-align: middle;
    align-items: center;
    background: #eeeeee;
    border-radius: 0px 0px 4px 4px;

    .menu_img {
      width: 24px;
      height: 24px;
      margin-right: 10px;
      margin-left: 12px;
      display: inline-block;

      img {
        width: 24px;
        height: 24px;
      }
    }

    .menu_text {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #303133;
    }
  }
}

/deep/ .el-dialog__body {
  padding: 0px 40px 30px 40px;
}

.login_doalog {
  //width: 516PX;
  //height: 441PX;
  //width: 2816PX;
  //height: 2641PX;
  //margin: auto;
}

.login_button {
  width: 100%;
  height: 58px;
  background: #6647ff;
  border: #6647ff;
  border-radius: 5px;
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 28px;
  cursor: pointer;
}

.login_button:hover {
  width: 100%;
  height: 58px;
  background: #6647ff;
  border-radius: 5px;
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 28px;
}

/deep/ .el-input-group__append {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #6647ff;
  line-height: 22px;
  width: 92px;
  background-color: #ffffff;
  text-align: center;
}

.peer-in-use-dialog {
  /deep/ .el-dialog__header {
    height: 0;
    background: #ffffff;
    border-radius: 4px 4px 0px 0px;
    text-align: center;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .el-dialog__title {
      height: 24px;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 24px;
    }
  }

  /deep/ .el-dialog__body {
    padding: 0px;
    max-height: 499px;
  }
}

.close_time_left {
  width: 129px;
  height: 26px;
  background: #b7b7b7;
  border-radius: 2px;
  border: 1px solid rgba(255, 255, 255, 0.6);
  color: #ffffff;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
  position: relative;
  float: right;
  top: -28px;
  z-index: 999;
}

.close_time_right {
  width: 107px;
  height: 26px;
  background: #b7b7b7;
  border-radius: 2px;
  border: 1px solid rgba(255, 255, 255, 0.6);
  color: #ffffff;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
  position: relative;
  float: right;
  top: -28px;
  z-index: 999;
  margin-left: 10px;
  cursor: pointer;
}

.close_time_img {
  position: relative;
  top: -29px;
}

.phone_dialog {
  width: 516px;
  height: 312px;
}
</style>
