// router.js
import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../Layout/layout.vue';
// 懒加载
const Login = () => import('../views/login/loginIndex.vue');
const clientlogin = () => import('../views/clientLogin.vue');

//  禁止路由自己跳自己
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return routerPush.call(this, location).catch(error => error)
}

//使用路由插件
Vue.use(VueRouter)

// 必须要显示的静态路由表
// 用hidden: true 在列表中不显示该菜单栏目
const routes = [
    // {
    //     path: '',
    //     name: 'LoginPage',
    //     component: Login,
    //     hidden: true
    // },
    {
        path: '/login',
        name: 'LoginPage',
        component: Login,
        hidden: true
    },
    {
        path: '/clientlogin',
        name: 'clientlogin',
        hidden: true,
        component: clientlogin,
    },
    {
        path: '/oWeb',
        name: 'SystemHomepage',
        component: () => import('../views/SystemHomepage.vue'),
        hidden: true
    },
    { // 投流推广页面
        path: '/adLogin',
        name: 'adLogin',
        component: () => import('../views/SystemHomepageAd.vue'),
        hidden: true
    },
    {
        path: '/choosecompany',
        hidden: true,
        component: () => import('@/views/choosecompany.vue'),
        children: [{
            path: 'choosecompany',
            name: 'choosecompany',
            component: () => import('@/views/choosecompany.vue'),
            meta: { title: '选择店铺' }
        }]
    },
    {
        path: '/DK',
        hidden: true,
        component: () => import('@/views/DK/guideindex.vue'),
        children: [{
            path: 'guideindex',
            name: 'guideindex',
            component: () => import('@/views/DK/guideindex.vue'),
            meta: { title: '抖开页面' }
        }]
    },
    {
        path: '/mobile',
        hidden: true,
        component: () => import('@/views/mobile_index/index.vue'),
        children: [{
            path: 'login',
            name: 'login',
            component: () => import('@/views/mobile_index/index.vue'),
            meta: {}
        }]
    },
    // 只有一级菜单的路由
    {
        path: '',
        // 此处使用了  命名视图
        component: Layout,  // 全局统一的布局文件
        children: [{
            path: 'index',
            name: 'home',
            // 懒加载
            component: () => import('@/views/index/index'),
            meta: {
                title: '首页',
                // icon:  () => import('@/assets/menu/login_s.png')
                icon: require('@/assets/menu/index_s.png')
            },
        }],
        isClientHidden: true
    },
    // 拥有二级菜单的路由
    {
        path: '/smartComments',
        component: Layout,
        name: 'smartComments',
        meta: {
            title: '智能评论',
            icon: require('@/assets/menu/login_s.png')
        },
        children: [
            {
                path: 'autoComment',
                name: 'autoComment',
                component: () => import('@/views/smartComments/autoComment/index'),
                meta: {
                    title: '自动发评',
                    icon: require('@/assets/menu/login_s.png')
                }
            },
            {
                path: 'automaticFeedback',
                name: 'automaticFeedback',
                component: () => import('@/views/smartComments/automaticFeedback/index'),
                meta: {
                    title: '自动回评',
                    icon: require('@/assets/menu/login_s.png')
                }
            },
            {
                path: "keywordsFeedback",
                name: "keywordsFeedback",
                component: () => import('@/views/smartComments/keywordsFeedback/index'),
                meta: {
                    title: '关键词转客服',
                    icon: require('@/assets/menu/login_s.png')
                },
                dyHidden: true
            }
        ],
    },
    // 评论监测
    {
        path: '/commentMonitoring',
        component: Layout,
        name: 'commentMonitoring',
        meta: { title: '评论监测' },
        versionTypeHidden: true,
        xhsHidden: true,
        children: [
            {
                path: 'commentAnalysis',
                name: 'commentAnalysis',
                component: () => import('@/views/commentMonitoring/commentAnalysis/index'),
                meta: {
                    title: '评论分析',
                    icon: require('@/assets/menu/index_s.png')
                }
            },
            {
                path: 'commentDetails',
                name: 'commentDetails',
                component: () => import('@/views/commentMonitoring/commentDetails/index'),
                meta: {
                    title: '评论详情',
                    icon: require('@/assets/menu/index_s.png')
                }
            },
            {
                path: 'vocabularyManagement',
                name: 'vocabularyManagement',
                component: () => import('@/views/commentMonitoring/vocabularyManagement/index'),
                meta: {
                    title: '词库管理',
                    icon: require('@/assets/menu/index_s.png')
                }
            },
            {
                path: 'commentDetails/detail',
                name: 'detail',
                component: () => import('@/views/commentMonitoring/commentDetails/detail'),
                hidden: true,
                meta: {
                    title: '评论详情',
                    icon: require('@/assets/menu/index_s.png'),
                }
            }
        ],
    },
    {
        path: '/productListInfo',
        // 此处使用了  命名视图
        component: Layout,  // 全局统一的布局文件
        children: [{
            path: 'index',
            name: 'listinfo',
            // 懒加载
            component: () => import('@/views/productListInfo/index'),
            meta: {
                title: '商品信息',
                icon: require('@/assets/menu/index_s.png')
            }
        }],
        bdshHidden: true,
        xhsHidden: true
    },
    {
        path: '/systemSettings',
        // 此处使用了  命名视图
        component: Layout,  // 全局统一的布局文件
        children: [{
            path: 'index',
            name: 'setting',
            // 懒加载
            component: () => import('@/views/systemSetting.vue'),
            meta: {
                title: '系统设置',
                icon: require('@/assets/menu/setting.png')
            }
        }]
    }
]

// 实例化
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

router.beforeEach((to, from, next) => {
    if (to.path === "/login") {
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
        if (flag) {
            // this.$router.replace('/mobile/login')
            window.location.href = `${location.origin}/mobile/login`
        } else {
            next();
            console.log('pc端')
        }
    } else if (to.path === "/mobile/login" || to.path === "/DK/guideindex" || to.path === "/adLogin" || to.path === "/oWeb" || to.path === '/clientlogin') {
        next();
    } else if (to.path === "/choosecompany" || to.path === "/") {
        const userInfo = JSON.parse(localStorage.getItem('userInfo')) || null
        if (userInfo) {
            const companyListinfo = userInfo.companyList
            if (companyListinfo.length < 1) {
                window.location.href = `${location.origin}/login`
            } else {
                if (to.path === "/" || to.path === "") {
                    window.location.href = `${location.origin}/index`
                } else {
                    next();
                }
            }
        } else {
            window.location.href = `${location.origin}/login`
        }
    } else if (to.path.includes('master')) {
        window.location.href = `${location.origin}/index`
    } else {
        let token = localStorage.getItem('token')
        if (token == undefined || token === "") {
            window.location.href = `${location.origin}/login`
        } else {
            if (to.path === "/index" && localStorage.getItem('isClient') == 1) {
                window.location.href = `${location.origin}/smartComments/autoComment`
            } else {
                next()
            }
        }
    }
})

// router.onError((err) => {
//     const pattern = /Loading chunk (\d)+ failed/g;
//     const isChunkLoadFailed = err.message.match(pattern);
//     if (isChunkLoadFailed) {
//         let chunkBool = sessionStorage.getItem('chunkError');
//         let nowTimes = Date.now();
//         if (chunkBool === null || chunkBool && nowTimes - parseInt(chunkBool) > 60000) {//路由跳转报错,href手动跳转
//             sessionStorage.setItem('chunkError', 'reload');
//             const targetPath = router.history.pending.fullPath;
//             window.location.href = window.location.origin + targetPath;
//         } else if (chunkBool === 'reload') { //手动跳转后依然报错,强制刷新
//             sessionStorage.setItem('chunkError', Date.now());
//             window.location.reload(true);
//         }
//     }
// })

export default router
