<template>
  <div>
    <router-view />
    <out-time></out-time>
  </div>
</template>

<script>
import OutTime from '@/components/outTime'
export default {
  name: 'AppMain',
  components: { OutTime }
}
</script>

<style scoped>
</style>